import React, { useState, useMemo, useCallback } from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'

import { today } from '../utils'
import { Button } from '../components/Buttons'
import { NextConcertList, PastConcertList } from '../components/ConcertList'
import media, { mediaHover } from '../styles/mq'

const alternateRowCss = css`
  --row-padding-horizontal: var(--space-medium);
  --row-padding-vertical: 1rem;

  tr {
    :nth-of-type(odd) {
      background-color: var(--color-lighter);
    }
    td {
      padding-top: var(--row-padding-vertical);
      padding-bottom: var(--row-padding-vertical);
      :last-child {
        padding-right: var(--row-padding-horizontal);
      }
      :first-of-type {
        padding-left: var(--row-padding-horizontal);
      }
    }
  }
`

const FilterScroll = styled.div`
  overflow-x: scroll;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const Filters = styled.div`
  --filter-height: 3rem;
  --filter-padding: 1.8rem;
  ${media.greaterThan('small')} {
    --filter-height: 3.6rem;
    --filter-padding: 2.2rem;
  }
  display: flex;
  margin: 0 calc(var(--space-smaller) * -1);
  margin-bottom: var(--space-larger);
  > div {
    display: flex;
    align-items: center;
    padding: 0 var(--filter-padding);
    height: var(--filter-height);
    border: 1px solid #f3e9db;
    border-radius: calc(var(--filter-height) * 0.5);
    text-transform: uppercase;
    white-space: nowrap;
    font-size: var(--font-size-smallest);
    font-weight: 600;
    color: var(--color-darker);
    margin: 0 var(--space-smaller);
    cursor: pointer;
    transition: all 250ms ease;
    &.active {
      background-color: var(--color-light);
      color: var(--color-brown);
    }
    ${mediaHover} {
      &:hover {
        background-color: var(--color-light);
        color: var(--color-brown);
      }
    }
  }
`

const ConcertsPage = ({
  data: {
    dato: { page, allConcerts, allBands }
  }
}) => {
  const { formatMessage: t } = useIntl()
  const [filters, setFilters] = useState([])
  const [showPastConcerts, setShowPastConcerts] = useState(false)

  const toggleFilterId = useCallback(
    (id) =>
      setFilters((f) => {
        if (f.includes(id)) return f.filter((f) => f !== id)
        else return [...f, id]
      }),
    []
  )

  const { title } = page

  const [newConcerts, pastConcerts] = useMemo(() => {
    const d = today()
    const formattedConcertDates = allConcerts
      .reverse()
      .map(({ date, ...rest }) => ({
        ...rest,
        date: new Date(date)
      }))

    const filteredConcerts = filters.length
      ? formattedConcertDates.filter((c) => filters.includes(c.band?.id))
      : formattedConcertDates

    const pastIndex = filteredConcerts.findIndex((c) => c.date >= d)

    return [
      filteredConcerts.slice(pastIndex),
      filteredConcerts.slice(0, pastIndex).reverse()
    ]
  }, [allConcerts, filters])

  return (
    <>
      <h1 className="c-large">{title}</h1>
      <FilterScroll>
        <div className="c-large">
          <Filters>
            {allBands.map(({ id, name, shortName }) => (
              <div
                key={id}
                onClick={() => toggleFilterId(id)}
                className={filters.includes(id) ? 'active' : ''}
              >
                {shortName || name}
              </div>
            ))}
          </Filters>
        </div>
      </FilterScroll>
      <div className="c-large separator" />
      <div className="c-large">
        <NextConcertList
          concerts={newConcerts}
          css={alternateRowCss}
          hideTitle
        />
      </div>

      <div
        className="c-large"
        style={{
          marginBottom: 'var(--space-largest)'
        }}
      >
        {!showPastConcerts ? (
          <Button
            onClick={() => setShowPastConcerts(true)}
            style={{ marginTop: '3rem' }}
          >
            {t({ id: 'show past concerts' })}
          </Button>
        ) : (
          <>
            <div style={{ marginTop: '3rem' }} />
            <PastConcertList concerts={pastConcerts} css={alternateRowCss} />
          </>
        )}
      </div>
    </>
  )
}

export default ConcertsPage

export const query = graphql`
  query ($locale: DATO_SiteLocale!) {
    dato {
      ...CommonData
      page: pageConcert(locale: $locale) {
        seo: _seoMetaTags {
          ...TagRecordFragment
        }
        slug
        title
      }
      allConcerts(first: 100, locale: $locale, orderBy: date_DESC) {
        ...ConcertRecordFragment
      }
      allBands(locale: $locale, orderBy: position_ASC) {
        id
        shortName
        name
      }
    }
  }
`
